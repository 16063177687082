import React, { Component } from 'react'

export default class translation extends Component {
    render() {
        let translatedText = this.props.textcontent
        
        const text = (text) => {
            if (text === "Countdown to"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "倒计时";
                    break;
                case "id-ID":
                    translatedText = "Countdown to";
                    break;
                case "th-TH":
                    translatedText = "นับถอยหลังสู่";
                    break;
                case "vi-VN":
                    translatedText = "ĐẾM NGƯỢC ĐẾN";
                    break;
                default:
                    translatedText = text;
                }
            }
                   
            if (text === "UEFA EURO 2020"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "欧洲杯2020";
                    break;
                case "id-ID":
                    translatedText = "UEFA EURO 2020";
                    break;
                case "th-TH":
                    translatedText = "ยูฟ่า ยูโร 2020";
                    break;
                case "vi-VN":
                    translatedText = "UEFA EURO 2020";
                    break;
                default:
                    translatedText = text;
                }
            }
                   
            if (text === "DAYS"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "天";
                    break;
                case "id-ID":
                    translatedText = "HARI";
                    break;
                case "th-TH":
                    translatedText = "วัน ";
                    break;
                case "vi-VN":
                    translatedText = "NGÀY";
                    break;
                default:
                    translatedText = text;
                }
            }
                   
            if (text === "HOURS"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "小时";
                    break;
                case "id-ID":
                    translatedText = "JAM";
                    break;
                case "th-TH":
                    translatedText = "ชั่วโมง";
                    break;
                case "vi-VN":
                    translatedText = "GIỜ";
                    break;
                default:
                    translatedText = text;
                }
            }
                   
            if (text === "MINS"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "分钟";
                    break;
                case "id-ID":
                    translatedText = "MENIT";
                    break;
                case "th-TH":
                    translatedText = "นาที";
                    break;
                case "vi-VN":
                    translatedText = "PHÚT";
                    break;
                default:
                    translatedText = text;
                }
            }
                   
            if (text === "SECS"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "秒";
                    break;
                case "id-ID":
                    translatedText = "DETIK";
                    break;
                case "th-TH":
                    translatedText = "วินาที";
                    break;
                case "vi-VN":
                    translatedText = "GIÂY";
                    break;
                default:
                    translatedText = text;
                }
            }

            return translatedText
        }

        return (
            <>{text(this.props.textcontent)}</>
        )
    }
}
