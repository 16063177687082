import React, { Component } from 'react'
import { readRemoteFile } from 'react-papaparse';

import PlayNow from './playnow'
import TextTranslation from './translation'

export default class ticketsearch extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            list: [] ,
            resultusername: '',
            resultranking: '',
            resultprize: '',
            resultdisplay: false,
            myusername: ''
        }
    }

    componentDidMount (){
        // Initialize Variables
        
        let csvfile = 'https://'+window.location.hostname+'/data/vip-journeytoeuro2020.csv'

        /* if (this.props.lang === 'zh-CN'){
            csvfile = 'https://'+window.location.hostname+'/data/rap-points_en.csv'
        }
        else if (this.props.lang === 'id-ID'){
            csvfile = 'https://'+window.location.hostname+'/data/rap-points_en.csv'
        }
        else if (this.props.lang === 'th-TH'){
            csvfile = 'https://'+window.location.hostname+'/data/rap-points_en.csv'
        }
        else {
            csvfile = 'https://'+window.location.hostname+'/data/rap-points_en.csv'
        } */

        readRemoteFile(csvfile, {
            header: true,
            dynamicTyping: true,
            complete: (results) => {
                this.setState({
                    list: results.data,
                });
            }
        });

        const username = document.getElementById("username")
        const search = document.getElementById("search")
        const searching = document.getElementById("searching")
        const searchsuccess = document.getElementById("searchsuccess")
        const searchbox = document.getElementById("searchbox")
        const searchfail = document.getElementById("searchfail")
        let ranking = ""
        let prizes = ""

        const compareValues = (key, order = 'asc')=> {
            return function innerSort(a, b) {
                if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                    // property doesn't exist on either object
                    return 0;
                }

                const varA = (typeof a[key] === 'string')
                    ? a[key].toUpperCase() : a[key];
                const varB = (typeof b[key] === 'string')
                    ? b[key].toUpperCase() : b[key];

                let comparison = 0;
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }

        const isFound = () => {
            return this.state.list
                        .filter( (item) => {
                            if(item.username !== undefined){
                                return item.username.toString().toLowerCase() === username.value.toString().toLowerCase()
                            }
                        })
        }

        search.onclick = () => {
            if (!!+(isFound().length)) {
                ranking = this.state.list.sort(compareValues('ticket', 'desc')).findIndex(item => item.username.toString().toLowerCase() === username.value.toString().toLowerCase())
                prizes = this.state.list.sort(compareValues('ticket', 'desc')).find(item => item.username.toString().toLowerCase() === username.value.toString().toLowerCase())
                this.setState({
                    resultusername: username.value,
                    resultranking: ranking+1,
                    resultprize: prizes.prize,
                    resultdisplay: true
                });
                if (!searching.classList.contains("hidden")){
                    searching.classList.toggle("hidden")
                }
                if (!searchbox.classList.contains("hidden")){
                    searchbox.classList.toggle("hidden")
                }
                if (!searchfail.classList.contains("hidden")){
                    searchfail.classList.toggle("hidden")
                }
                searchsuccess.classList.toggle("hidden")
            }
            else {                
                this.setState({
                    myusername: username.value,
                })
                console.log("cannot be found")
                if (!searching.classList.contains("hidden")){
                    searching.classList.toggle("hidden")
                }
                searchfail.classList.toggle("hidden")
            }
        }
    }

    render() {
        const addComa = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        const placeholder = (text) => {
            let translatedText = ''         
            switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "用户名";
                    break;
                case "id-ID":
                    translatedText = "Username M88 Anda";
                    break;
                case "th-TH":
                    translatedText = "ยูสเซอร์เนม M88 ของคุณ";
                    break;
                case "vi-VN":
                    translatedText = "Tên đăng nhập của bạn";
                    break;
                default:
                    translatedText = text;
            }
            return translatedText
        }

        return (
            <div className="popupcontent">
                <div id="searching">
                    <h4><TextTranslation lang={this.props.lang} textcontent="My Ranking" /></h4>
                    <p><TextTranslation lang={this.props.lang} textcontent="To check your points and ranking on the leaderboard, simply type your username below and then submit." /></p>
                </div>
                <div id="searchsuccess" className="searchsuccess hidden">
                    <h4><TextTranslation lang={this.props.lang} textcontent="Hi" /> <strong>{this.state.resultusername.toUpperCase()}</strong>,</h4>
                    {/* <br />
                    <p>
                    <span><TextTranslation lang={this.props.lang} textcontent="CONGRATULATIONS!" /></span><br/>
                    <TextTranslation lang={this.props.lang} textcontent="You have won" /></p>
                    <span>{this.state.resultprize}</span>
                    <br /><br />
                    <p><TextTranslation lang={this.props.lang} textcontent="Our customer service team will contact you soon on how to claim your prizes." /></p> */}
                    <br />
                    <div className="resultbox">
                        <div>
                            <p><TextTranslation lang={this.props.lang} textcontent="You have earned" /></p>
                            <span>{addComa(this.state.resultprize)} <TextTranslation lang={this.props.lang} textcontent="Points" /></span>
                        </div>
                        <div>
                            <p><TextTranslation lang={this.props.lang} textcontent="You currently ranked" /></p>
                            <span>{addComa(this.state.resultranking)}</span>
                        </div>
                    </div>
                    <br />
                    <p><TextTranslation lang={this.props.lang} textcontent="Continue playing on M88 to earn more points and get your chance to win more cash prize or VIP Tickets to watch FC Barcelona in Camp Nou." /></p>
                    <PlayNow lang={this.props.lang} />
                </div>
                <div id="searchfail" className="searchfail hidden">
                    <h4><TextTranslation lang={this.props.lang} textcontent="Hi" /> <strong>{this.state.myusername.toUpperCase()}</strong>,</h4>
                    <br />
                    <p><TextTranslation lang={this.props.lang} textcontent="Unfortunately we cannot see your username." /></p>
                    <p><TextTranslation lang={this.props.lang} textcontent="Or you may have search for a WRONG username." /></p>
                </div>
                <div id="searchbox" className="formbox">
                    <label><TextTranslation lang={this.props.lang} textcontent="Username" /></label>
                    <input type="text" placeholder={placeholder("Your M88 Username")} id="username"/>
                    <button id="search"><TextTranslation lang={this.props.lang} textcontent="Submit" /></button>
                </div>
            </div>
        )
    }
}
