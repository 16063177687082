import React, { Component } from 'react'

export default class translation extends Component {
    render() {
        let translatedText = this.props.textcontent
        
        const text = (text) => {
            if (text === "My Tickets"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "我的抽奖券";
                    break;
                case "id-ID":
                    translatedText = "Tiket Saya";
                    break;
                case "th-TH":
                    translatedText = "ตั๋วของฉัน";
                    break;
                case "vi-VN":
                    translatedText = "Vé Rút Thăm của tôi";
                    break;
                default:
                    translatedText = text;
                }
            }

            if (text === "To check your points and ranking on the leaderboard, simply type your username below and then submit."){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "在下方输入您的用户名，提交，就可在排行榜上查看您的积分和排名。";
                    break;
                case "id-ID":
                    translatedText = "Untuk memeriksa poin dan peringkat Anda di papan peringkat, cukup ketik Username Anda di bawah ini dan kemudian kirimkan.";
                    break;
                case "th-TH":
                    translatedText = "ตรวจสอบคะแนนและอันดับของคุณ บนลีดเดอร์บอร์ด ง่ายๆ เพียง พิมพ ยูสเซอร์เนมของคุณ ลงด้านล่าง และกดยอมรับ";
                    break;
                case "vi-VN":
                    translatedText = "Để kiểm tra điểm, hãy tìm kiếm bằng cách điền tên đăng nhập để xem vị trí của bạn trên Bảng Xếp Hạng và nhấp xác nhận.";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Your M88 Username"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "用户名";
                    break;
                case "id-ID":
                    translatedText = "Username M88 Anda";
                    break;
                case "th-TH":
                    translatedText = "ยูสเซอร์เนม M88 ของคุณ";
                    break;
                case "vi-VN":
                    translatedText = "Tên đăng nhập của bạn";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Submit"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "提交";
                    break;
                case "id-ID":
                    translatedText = "Kirim";
                    break;
                case "th-TH":
                    translatedText = "ยืนยัน";
                    break;
                case "vi-VN":
                    translatedText = "Kiểm tra";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Unfortunately we cannot see your username."){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "很遗憾，查询不到您的用戶名。";
                    break;
                case "id-ID":
                    translatedText = "Mohon maaf kami tidak dapat menemukan Username Anda.";
                    break;
                case "th-TH":
                    translatedText = "ขออภัย เราไม่พบยูสเซอร์เนมของคุณ";
                    break;
                case "vi-VN":
                    translatedText = "Rất tiếc, tên đăng nhập của bạn chưa có dữ liệu. Hãy kiểm tra lại sau.";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Or you may have search for a WRONG username."){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "或者您可能输入了错误的用户名。";
                    break;
                case "id-ID":
                    translatedText = "atau Anda mungkin memasukan username yang SALAH.";
                    break;
                case "th-TH":
                    translatedText = "หรือคุณอาจจะใส่ยูเซอร์เนมไม่ถูกต้อง";
                    break;
                case "vi-VN":
                    translatedText = "Hoặc bạn cung cấp TÊN ĐĂNG NHẬP chưa đúng";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Hi"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "Hi";
                    break;
                case "id-ID":
                    translatedText = "Hi";
                    break;
                case "th-TH":
                    translatedText = "สวัสดีค่ะ";
                    break;
                case "vi-VN":
                    translatedText = "Xin chào";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "CONGRATULATIONS!"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "恭喜恭喜！";
                    break;
                case "id-ID":
                    translatedText = "CONGRATULATIONS!";
                    break;
                case "th-TH":
                    translatedText = "ยินดีด้วย!";
                    break;
                case "vi-VN":
                    translatedText = "XIN CHÚC MỪNG!";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "You have won"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "您中奖了！！！";
                    break;
                case "id-ID":
                    translatedText = "Anda Memenangkan";
                    break;
                case "th-TH":
                    translatedText = "คุณได้รางวัล";
                    break;
                case "vi-VN":
                    translatedText = "Bạn đã trúng ";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "You have earned"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "您已经获得";
                    break;
                case "id-ID":
                    translatedText = "Anda telah mendapatkan";
                    break;
                case "th-TH":
                    translatedText = "คุณได้รับ";
                    break;
                case "vi-VN":
                    translatedText = "Bạn đang có";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "You currently ranked"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "您目前排名";
                    break;
                case "id-ID":
                    translatedText = "Anda saat ini diperingkat";
                    break;
                case "th-TH":
                    translatedText = "อันดับของคุณในตอนนี้";
                    break;
                case "vi-VN":
                    translatedText = "Xếp hạng hiện tại";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Our customer service team will contact you soon on how to claim your prizes."){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "M88客服团队将会尽快和您联系并告知相关中奖事宜，非常感谢您的支持！";
                    break;
                case "id-ID":
                    translatedText = "M88 akan segera menghubungi Anda untuk cara mengklaim hadiah Anda.";
                    break;
                case "th-TH":
                    translatedText = "ฝ่ายบริการลูกค้าจะทำการติดต่อคุณ เพื่อรับของรางวัลนะคะ";
                    break;
                case "vi-VN":
                    translatedText = "Bộ phận Chăm Sóc Khách Hàng của M88 sẽ liên hệ bạn sớm nhất về cách nhận giải thưởng.";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Continue playing on M88 to earn more points and get your chance to win more cash prize or VIP Tickets to watch FC Barcelona in Camp Nou."){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "立即下注吧！下注多多！赢多多！即可获得更多积分，免费奖金，并有机会赢得诺坎普球场VIP门票现场观看巴塞罗那赛事的机会呦！";
                    break;
                case "id-ID":
                    translatedText = "Lanjutkan permainan Anda di M88 untuk dapatkan lebih banyak poin dan kesempatan untuk memenangkan lebih banyak hadiah uang tunai atau Tiket VIP untuk menonton FC Barcelona di Camp Nou";
                    break;
                case "th-TH":
                    translatedText = "เล่นอย่างต่อเนื่องกับ M88 เพื่อรับคะแนนมากขึ้น และคว้าโอกาสที่จะชนะเงินรางวัล หรือ ตั๋ว VIP เข้าชมทีม FC บาร์เซโลน่า ในสนาม คัมป์นู";
                    break;
                case "vi-VN":
                    translatedText = "Hãy tiếp tục tham gia để tích lũy nhiều điểm hơn và cơ hội chiến thắng sẽ trong tầm tay của bạn. Rất nhiều giải Tiền thưởng và Giải Đặc Biệt: vé VIP xem FC Barcelona tại sân Camp Nou đang chờ bạn.";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Leaderboard"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "排行榜";
                    break;
                case "id-ID":
                    translatedText = "PAPAN PERINGKAT";
                    break;
                case "th-TH":
                    translatedText = "ลีดเดอร์บอร์ด";
                    break;
                case "vi-VN":
                    translatedText = "BẢNG XẾP HẠNG";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "VIP JOURNEY TO EURO 2020"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "VIP 2020欧洲杯之旅";
                    break;
                case "id-ID":
                    translatedText = "VIP JOURNEY TO EURO 2020";
                    break;
                case "th-TH":
                    translatedText = "VIP เส้นทางสู่ยูโร 2020";
                    break;
                case "vi-VN":
                    translatedText = "VIP HÀNH TRÌNH ĐẾN EURO 2020";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Promo Period: May 12 - June 11, 2021"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "优惠时间：2021年05月12日-06月11日 ";
                    break;
                case "id-ID":
                    translatedText = "Periode Promosi : 12 Mei - 11 Juni 2021";
                    break;
                case "th-TH":
                    translatedText = "ระยะเวลาโปรโมชั่น : 12 พ.ค - 11 มิ.ย 64";
                    break;
                case "vi-VN":
                    translatedText = "Thời gian diễn ra: 12/05 - 11/06/2021";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Top 20"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "前20名";
                    break;
                case "id-ID":
                    translatedText = "Top 20";
                    break;
                case "th-TH":
                    translatedText = "อันดับ 20 คนแรก";
                    break;
                case "vi-VN":
                    translatedText = "Top 20";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Top 1 - 10"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "前 1 - 10 名";
                    break;
                case "id-ID":
                    translatedText = "Top 1 - 10";
                    break;
                case "th-TH":
                    translatedText = "อันดับ 1-10";
                    break;
                case "vi-VN":
                    translatedText = "Top 1 - 10";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Top 11 - 20"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "前 11 - 20 名";
                    break;
                case "id-ID":
                    translatedText = "Top 11 - 20";
                    break;
                case "th-TH":
                    translatedText = "อันดับ 11-20";
                    break;
                case "vi-VN":
                    translatedText = "Top 11 - 20";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Top 21 - 30"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "前 21 - 30 名";
                    break;
                case "id-ID":
                    translatedText = "Top 21 - 30";
                    break;
                case "th-TH":
                    translatedText = "อันดับ 21-30";
                    break;
                case "vi-VN":
                    translatedText = "Top 21 - 30";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Username"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "用户名";
                    break;
                case "id-ID":
                    translatedText = "Username";
                    break;
                case "th-TH":
                    translatedText = "ยูสเซอร์เนม";
                    break;
                case "vi-VN":
                    translatedText = "Tên Đăng Nhập";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Points"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "积分";
                    break;
                case "id-ID":
                    translatedText = "Poin";
                    break;
                case "th-TH":
                    translatedText = "คะแนน";
                    break;
                case "vi-VN":
                    translatedText = "Điểm";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Prizes"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "奖金/品";
                    break;
                case "id-ID":
                    translatedText = "HADIAH";
                    break;
                case "th-TH":
                    translatedText = "รางวัล";
                    break;
                case "vi-VN":
                    translatedText = "GIẢI THƯỞNG";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Tickets"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "抽奖券";
                    break;
                case "id-ID":
                    translatedText = "Tiket";
                    break;
                case "th-TH":
                    translatedText = "ตั๋ว";
                    break;
                case "vi-VN":
                    translatedText = "Vé Rút Thăm";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Easy search - Find my username"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "快速查询-我的用户名";
                    break;
                case "id-ID":
                    translatedText = "PENCARIAN CEPAT UNTUK USERNAME ANDA";
                    break;
                case "th-TH":
                    translatedText = "ค้นหาชื่อของคุณได้ง่ายขึ้น";
                    break;
                case "vi-VN":
                    translatedText = "TÌM TÊN ĐĂNG NHẬP CỦA BẠN";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Check out how many points you've got! Search for your username to see if you rank well in the leaderboard."){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "查看您获得多少积分吧！ 搜索用戶名，以查看您的排名是否在排行榜靠前。";
                    break;
                case "id-ID":
                    translatedText = "Lihat berapa banyak poin yang Anda dapatkan! Cari Username Anda untuk melihat apakah Anda mendapat peringkat yang baik di papan peringkat.";
                    break;
                case "th-TH":
                    translatedText = "มาดูกัน ว่าคุณได้กี่คะแนนแล้ว! ค้นหารายชื่อเพื่อตรวจสอบอันดับของคุณบนลีดเดอร์บอร์ด";
                    break;
                case "vi-VN":
                    translatedText = "Kiểm tra số điểm bạn đang có! Tìm kiếm  tên đăng nhập để xem vị trí của bạn trên Bảng Xếp Hạng";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "My Ranking"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "我的排名";
                    break;
                case "id-ID":
                    translatedText = "Peringkat Saya";
                    break;
                case "th-TH":
                    translatedText = "อันดับของฉัน ";
                    break;
                case "vi-VN":
                    translatedText = "Xếp hạng của tôi";
                    break;
                default:
                    translatedText = text;
                }
            }
            
            if (text === "Play Now!"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "立即游戏";
                    break;
                case "id-ID":
                    translatedText = "MAIN SKRG!";
                    break;
                case "th-TH":
                    translatedText = "เล่นเลยตอนนี้!";
                    break;
                case "vi-VN":
                    translatedText = "Cược Ngay!";
                    break;
                default:
                    translatedText = text;
                }
            }

            return translatedText
        }

        return (
            <>{text(this.props.textcontent)}</>
        )
    }
}
