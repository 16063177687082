import React, { Component } from 'react'

import '../styles/cta.css'

import TextTranslation from './translation'
import TicketSearch from './ticketsearch'

export default class cta extends Component {
    componentDidMount (){
        // Initialize Variables
        const closePopup = document.getElementById("popupclose");
        const overlay = document.getElementById("overlay");
        const popup = document.getElementById("popup");
        const myprize = document.getElementById("myprize");
        // Close Popup Event
        closePopup.onclick = () => {
            overlay.className = ''; /*This removes all classes, use at your own risk*/
            popup.className = ''; /*This removes all classes, use at your own risk*/
        };
        // Close Popup Event
        overlay.onclick = () => {
            overlay.className = ''; /*This removes all classes, use at your own risk*/
            popup.className = ''; /*This removes all classes, use at your own risk*/
        };
        // Show Overlay and Popup
        myprize.onclick = () => {
            overlay.className = 'show';
            popup.className = 'show';
        }
    }

    render() {
        return (
            <section>
                <div className="container cta">
                    <h3><TextTranslation lang={this.props.lang} textcontent="Easy search - Find my username" /></h3>
                    <p><TextTranslation lang={this.props.lang} textcontent="Check out how many points you've got! Search for your username to see if you rank well in the leaderboard." /></p>
                    <button id="myprize"><TextTranslation lang={this.props.lang} textcontent="My Ranking" /></button>
                </div>

                <div id="overlay"></div>
                <div id="popup">
                    <div className="popupcontrols">
                        <div id="popupclose">
                            <div className="mdiv"><div className="md"></div></div>
                        </div>
                    </div>
                    {<TicketSearch lang={this.props.lang} view={this.props.view}/>}
                </div>
            </section>
        )
    }
}
