import React, { Component } from 'react'

export default class tnc_en extends Component {
    componentDidMount(){      
        var acc = document.getElementById("accordion");

        acc.addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        } 
        });
    }
    render() {
        return (
            <div className="container tnc">
                <h2>优惠活动的规则与条款</h2>
                <button id="accordion" className="accordion">M88邀您点燃激情！下注沙巴体育/明陞体育！</button>
                <div className="panel">
                    <div className="content">
                        <p>赢得现场观看巴塞罗那赛事VIP门票+巨额奖金！</p>
                        <h3>规则与条款:</h3>
                        <div className="row">
                            <ol>
                                <li>此优惠面向所有M88黄金VIP及以上，中国，越南，泰国，印度尼西亚和马来西亚活跃会员。
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>活动时间</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong>2021年05月12</strong>日00:00:01至 <strong>2021年06月11</strong>日23:59:59（GMT+8）</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>活动期间，会员于“明陞体育”及“沙巴体育”进行投注累积积分，根据积分排名获得免费奖金，还有机会赢得巴萨罗纳足球俱乐部VIP门票，详情如下：
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>会员级别</td>
                                                <td>每日累积<br />总成功存款金额</td>
                                                <td>每日累积<br />总有效投注额</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>黄金VIP<br />白金VIP<br />钻石VIP</td>
                                                <td>≥ 12,272元</td>
                                                <td>≥ 18,772 元</td>
                                            </tr>
                                            <tr>
                                                <td>可获得积分</td>
                                                <td>1</td>
                                                <td>1</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ul>
                                        <li>活动期间积分最高之会员为赢家。</li>
                                        <li>积分可至排行榜进行查询，更新时间每周一18:00 (GMT+8)。</li>
                                    </ul>
                                </li>
                                <li>会员需点击 <strong>“选择参加”</strong> 按钮（登录后）申请并提交。</li>
                                <li>如果有两位或更多玩家的积分相同，则首先达到该积分的玩家将在排行榜上排名靠前, 获得更高名次奖金。</li>
                                <li>奖金需5倍流水投注于“明陞体育”或“沙巴体育”中即可申请提款。</li>
                                <li>仅计入所有已结算的注单。所有无效, 对赌以及任何低于1.50（欧洲盘）赔率的投注（马来盘赔率0.50 ；香港盘赔率0.50；印尼盘赔率-2.00）等将不计算在有效投注内。</li>
                                <li>仅计算真钱投注。</li>
                                <li>球赛门票/奖品中奖者将于活动结束后5天内收到通知。如未能联系到得奖者，则该奖项将视为无效，并在期限结束后将不再进行抽奖。 </li>
                                <li>若会员符合条件，奖金将会在优惠活动结束后5天内自动派发到您的钱包。未使用奖金将在成功派发的5天后自动过期。 </li>
                                <li>会员必须在30天内完成有效投注额要求，否则系统将自动清除赢利和红利奖金。</li>
                                <li>一般条款及规则应用于此优惠。</li>
                            </ol>
                            <ol>
                                <strong>奖金表如下所示：</strong>
                                <table className="prizetable">
                                    <thead>
                                        <tr>
                                            <td colSpan={3}><strong>锦标赛</strong></td>
                                        </tr>
                                        <tr>
                                            <td><strong>排名 </strong></td><td><strong>奖金 </strong></td><td><strong>奖品</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>头奖</td><td colSpan={2}><strong>巴萨罗纳足球俱乐部VIP门票 </strong></td>
                                        </tr>
                                        <tr>
                                            <td>1</td><td>79,200元</td><td rowSpan={5}>西甲大使限量亲笔签名衣 </td>
                                        </tr>
                                        <tr>
                                            <td>2</td><td>50,000元</td>
                                        </tr>
                                        <tr>
                                            <td>3</td><td>40,000元</td>
                                        </tr>
                                        <tr>
                                            <td>4</td><td>30,000元</td>
                                        </tr>
                                        <tr>
                                            <td>5</td><td>23,000元</td>
                                        </tr>
                                        <tr>
                                            <td>6</td><td>16,000元</td><td rowSpan={5}>西甲大使限量亲笔签名足球 </td>
                                        </tr>
                                        <tr>
                                            <td>7</td><td>10,000元</td>
                                        </tr>
                                        <tr>
                                            <td>8</td><td>6,500元</td>
                                        </tr>
                                        <tr>
                                            <td>9</td><td>6,000元</td>
                                        </tr>
                                        <tr>
                                            <td>10</td><td>5,000元</td>
                                        </tr>
                                        <tr>
                                            <td>11 - 20</td><td>3,300元</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>21 - 30</td><td>2,640元</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>31 - 50</td><td>1,980元</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>51 - 100</td><td>1,320元</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>101 - 200</td><td>660元</td><td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p><strong>头奖：至诺坎普现场观看巴塞罗那队比赛VIP门票2张（一位中奖者）</strong></p>
                                <p>详情如下：</p>
                                <ul>
                                    <li>获奖者所属国家来回商务舱机票2张 </li>
                                    <li>机场接送 </li>
                                    <li>五天四夜五星级酒店住宿(靠近诺坎普体育馆) </li>
                                    <li>中奖者需自行办理至巴塞罗那西班牙之签证，网站不负责签证手续及相关费用。 </li>
                                    <li>网站负责选择航空公司和酒店。 </li>
                                    <li>大奖可折现为65,000元，不需流水即可提款。 </li>
                                </ul>
                                <p><strong>排名 1~ 5：</strong>奖金+西甲大使限量亲笔签名衣 </p>
                                <p><strong>排名6~10：</strong>奖金+西甲大使限量亲笔签名足球 </p>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
