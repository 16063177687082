import './App.css';

import Header from './components/header'
import Promovideo from './components/promovideo'
import Leaderboard from './components/leaderboard'
import TnC from './components/tnc'
import CTA from './components/cta'
import Footer from './components/footer'

import React, { Component } from 'react'

export default class App extends Component {
  render() {   

    const getUrlVars = () => {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    const getUrlParam = (parameter, defaultvalue) => {
        var urlparameter = defaultvalue;
        if(window.location.href.indexOf(parameter) > -1){
            urlparameter = getUrlVars()[parameter];
            }
        return urlparameter;
    }

    return (
      <div className="App">
          <Header lang={getUrlParam('lang','en-US')} />
          <Promovideo lang={getUrlParam('lang','en-US')} />
          <main className={getUrlParam('lang','en-US')}>
              <Leaderboard lang={getUrlParam('lang','en-US')} />
              <CTA lang={getUrlParam('lang','en-US')} />
              <TnC lang={getUrlParam('lang','en-US')}/>
          </main>
          <Footer lang={getUrlParam('lang','en-US')}/>
      </div>
    )
  }
}