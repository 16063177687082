import React, { Component } from 'react'
import classes from '../styles/countdown.module.css';

import TextContent from './countdown.content.js'

export default class ticketsearch extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            days: '',
            hours: '',
            minutes: '',
            seconds: ''
        }
    }
    componentDidMount (){
        // Set the date we're counting down to
        var countDownDate = new Date("June 12, 2021 03:00:00").getTime()
        var setTimezone = +8 //GMT+8
        
        // Update the count down every 1 second
        var x = setInterval( () => {
        
          // Get today's date and time
          var now = new Date().getTime();
          var timeoffset = new Date().getTimezoneOffset();
          var timedifference = ((setTimezone*60)+timeoffset) * 60 * 1000; //((setTimezone * 60sec) + timeoffset) * 60sec * 1000milisec
            
          // Find the distance between now and the count down date
          var distance = countDownDate - (now + timedifference);
            
          // Time calculations for days, hours, minutes and seconds
        this.setState({
            days : Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds : Math.floor((distance % (1000 * 60)) / 1000),
        })
            
          // If the count down is over, write some text 
          if (distance < 0) {
            clearInterval(x);
            document.getElementById("countdown").innerHTML = "EXPIRED";
          }
        }, 1000);
    }

    render() {

        return (
            <div className={classes.container} >
                <div className={classes.countdownbox} >
                    <div className={classes.textpresenter} ><TextContent lang={this.props.lang} textcontent="Countdown to" /><br /><TextContent lang={this.props.lang} textcontent="UEFA EURO 2020" /></div>
                    <div className={classes.days} ><span className={classes.count}>{this.state.days}</span> <TextContent lang={this.props.lang} textcontent="DAYS" /></div>
                    <div className={classes.divider} >:</div>
                    <div className={classes.hours} ><span className={classes.count}>{this.state.hours}</span> <TextContent lang={this.props.lang} textcontent="HOURS" /></div>
                    <div className={classes.divider} >:</div>
                    <div className={classes.minutes} ><span className={classes.count}>{this.state.minutes}</span> <TextContent lang={this.props.lang} textcontent="MINS" /></div>
                    <div className={classes.divider} >:</div>
                    <div className={classes.seconds} ><span className={classes.count}>{this.state.seconds}</span> <TextContent lang={this.props.lang} textcontent="SECS" /></div>
                    <p id="countdown"></p>
                </div>
            </div>
        )
    }
}
