import React, { Component } from 'react'

export default class translation extends Component {
    render() {
        let translatedText = this.props.textcontent
        
        const text = (text) => {
            if (text === "Tap Here to Add Sound"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "点击此处开启声音";
                    break;
                case "id-ID":
                    translatedText = "Klik untuk Menambahkan Suara Video";
                    break;
                case "th-TH":
                    translatedText = "กดที่นี่ เพื่อเปิดเสียงวีดีโอ";
                    break;
                case "vi-VN":
                    translatedText = "Nhấp để bật âm thanh";
                    break;
                default:
                    translatedText = text;
                }
            }
            if (text === "Tap to Mute Video"){
                switch (this.props.lang) {
                case "zh-CN":
                    translatedText = "点击此处关闭声音";
                    break;
                case "id-ID":
                    translatedText = "Klik untuk Mematikan Suara Video";
                    break;
                case "th-TH":
                    translatedText = "กดที่นี่ เพื่อปิดเสียงวีดีโอ";
                    break;
                case "vi-VN":
                    translatedText = "Nhấp để tắt âm thanh";
                    break;
                default:
                    translatedText = text;
                }
            }

            return translatedText
        }

        return (
            <>{text(this.props.textcontent)}</>
        )
    }
}
