import React, { Component } from 'react'

export default class tnc_en extends Component {
    componentDidMount(){      
        var acc = document.getElementById("accordion");

        acc.addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        } 
        });
    }
    render() {
        return (
            <div className="container tnc">
                <h2>Điều kiện và Điều khoản</h2>
                <button id="accordion" className="accordion">VIP HÀNH TRÌNH ĐẾN EURO 2020</button>
                <div className="panel">
                    <div className="content">
                        <p>KHỞI ĐỘNG CÙNG M88! TIỀN THƯỞNG VÀ VÉ XEM TRỰC TIẾP ĐANG CHỜ BẠN.</p>
                        <h3>Điều kiện &amp; Điều khoản:</h3>
                        <div className="row">
                            <ol>
                                <li>Chương trình dành cho các thành viên VIP cấp bậc Vàng, Bạch Kim, Kim Cương tại M88.
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>Thời gian khuyến mãi </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> Từ 00:00:00 <strong>12/05/2021  </strong> đến 23:59:59 <strong>11/06/2021 (GMT+8) </strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>Trong thời gian khuyến mãi, Thành viên cần tham gia tại M Thể Thao/SaBa có tổng điểm cao nhất sẽ có cơ hội nhận được vé VIP xem trực tiếp trận đấu của FC Barcelona tại sân vận động Camp Nou và các giải thưởng trên bảng xếp hạng.</li>
                                <li>Cách tính điểm như sau:
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>Cách tích lũy điểm</td>
                                                <td>Nhận được</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Cứ mỗi ≥ 43,424 VND Tổng tiền gửi thành công hàng ngày</td>
                                                <td>1 điểm</td>
                                            </tr>
                                            <tr>
                                                <td>Cứ mỗi ≥ 66,424 Tổng tiền cược hàng ngày</td>
                                                <td>1 điểm</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ul>
                                        <li>Người chiến thắng được tính trên tổng điểm cao nhất trong thời gian khuyến mãi</li>
                                        <li>Tổng điểm sẽ được kiểm tra và cập nhật vào Bảng xếp hạng vào 18:00 GMT+8 Thứ Hai hàng tuần.</li>
                                        <li>Tổng tiền cược được tính dựa trên tiền gửi &amp; tiền hoàn trả.</li>
                                    </ul>
                                </li>
                                <li>Sau khi đăng nhập, thành viên cần nhấp "CHỌN THAM GIA" và chọn "THAM GIA” trước khi chương trình kết thúc. Thành viên đã CHỌN THAM GIA thì không thể yêu cầu hủy.</li>
                                <li>Tất cả các cược HÒA, cược HỦY, cược 2 bên, kiểu cược Decimal có tỷ lệ dưới 1.5 (Malay odds 0.50; HK odds 0.50; Indo Odds -2.00) và tất cả trò chơi trong danh sách ngoại lệ sẽ không được tính vào chương trình này.</li>
                                <li>Tiền thưởng cần trải qua 5 vòng cược tại SABA Thể Thao/ M thể thao trước khi tiến hành rút tiền.  </li>
                                <li>Tiền thưởng sẽ được cập nhật trong 5 ngày &amp; có thời hạn sử dụng trong 5 ngày kể từ khi cập nhật. M88 sẽ không tiến hành tái cập nhật với bất cứ lý do nào từ thành viên.  </li>
                                <li>Giải thưởng sẽ bị hủy nếu thành viên không liên lạc được trong vòng 5 ngày sau khi chương trình khuyến mãi kết thúc. </li>
                                <li>Số vòng tái đặt cược yêu cầu cần đạt được trong vòng 30 ngày để tránh việc tiền thưởng và tiền thắng cược bị hủy.  </li>
                                <li>Các Điều kiện &amp; Điều khoản chung của chương trình Khuyến mãi được áp dụng.  </li>
                            </ol>
                            <ol>
                                <strong>Cơ cấu giải thưởng:</strong>
                                <table className="prizetable">
                                    <thead>
                                        <tr>
                                            <td><strong>Xếp hạng</strong></td><td><strong>Giải thưởng (VND)</strong></td><td><strong>Giải thưởng phụ</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Giải Đặc biệt</td><td colSpan={2}>Vé VIP xem trực tiếp FC Barcelona tại sân Camp Nou</td>
                                        </tr>
                                        <tr>
                                            <td>1</td><td>276,000</td><td rowSpan={5}>1 Áo thi đấu phiên bản giới hạn có chữ ký của Đại sứ La Liga</td>
                                        </tr>
                                        <tr>
                                            <td>2</td><td>177,000</td>
                                        </tr>
                                        <tr>
                                            <td>3</td><td>138,000</td>
                                        </tr>
                                        <tr>
                                            <td>4</td><td>103,000</td>
                                        </tr>
                                        <tr>
                                            <td>5</td><td>80,000</td>
                                        </tr>
                                        <tr>
                                            <td>6</td><td>57,000</td><td rowSpan={5}>1 Quả Bóng phiên bản giới hạn có chữ ký của Đại sứ La Liga</td>
                                        </tr>
                                        <tr>
                                            <td>7</td><td>35,000</td>
                                        </tr>
                                        <tr>
                                            <td>8</td><td>23,000</td>
                                        </tr>
                                        <tr>
                                            <td>9</td><td>20,000</td>
                                        </tr>
                                        <tr>
                                            <td>10</td><td>18,000</td>
                                        </tr>
                                        <tr>
                                            <td>11-20</td><td>11,500</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>21-30</td><td>9,200</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>31-50</td><td>6,900</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>51-100</td><td>4,600</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>101-200</td><td>2,300</td><td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p><strong>Giải Đặc Biệt: Vé VIP xem trực tiếp FC Barcelona tại sân Camp Nou</strong><br />Ghi chú:</p>
                                <ul>
                                    <li>Cặp vé máy bay khứ hồi - hạng thương gia từ quốc gia của thành viên (1 giải)</li>
                                    <li>Xe đưa đón sân bay</li>
                                    <li>5N4D tại khách sạn 5 sao (địa điểm gần sân vận động Camp Nou)</li>
                                    <li>Thành viên tự túc chuẩn bị các thủ tục nhập cảnh cần thiết đến thành phố Barcelona, Tây Ban Nha.</li>
                                    <li>M88 sẽ quyết định hãng máy bay &amp; khách sạn</li>
                                    <li>Giải Đặc Biệt sẽ bị hủy (và không tái cập nhật với bất kì lý do gì) nếu không liên hệ được với thành viên thắng giải trong 5 ngày từ khi kết thúc chương trình.</li>
                                    <li>Tiền thưởng quy đổi cho Giải Đặc Biệt là 230,000 VND không yêu cầu tái đặt cược</li>
                                </ul>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
