import React, { Component } from 'react'

export default class tnc_en extends Component {
    componentDidMount(){      
        var acc = document.getElementById("accordion");

        acc.addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        } 
        });
    }
    render() {
        return (
            <div className="container tnc">
                <h2>Promotion Terms &amp; Conditions</h2>
                <button id="accordion" className="accordion">VIP JOURNEY TO EURO 2020</button>
                <div className="panel">
                    <div className="content">
                        <p>EURO 2020 COMING SOON WITH MEGA PRIZES AWAIT YOU!</p>
                        <h3>Terms &amp; Conditions:</h3>
                        <div className="row">
                            <ol>
                                <li>This promotion is applicable to all VIP Gold, Platinum, and Diamond members in Malaysia, China, Vietnam, Indonesia and Thailand. 
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>PROMOTION PERIOD</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong>May 12, 2021</strong> 00:00:00 to <strong>June 11, 2021</strong> 23:59:59 (GMT+8)</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>During the promotion period, members who play in "<strong>SABA &amp;/or MSPORTS</strong>" with highest total points will get a chance to win <strong>VIP tickets</strong> to watch <strong>FC Barcelona</strong> match live in <strong>Camp Nou</strong>.</li>
                                <li>To win the prize, member must collect the points based on the criteria below,
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>How to earn Point – VIP Gold, Platinum, Diamond </td>
                                                <td>Point/s Earned</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Every accumulated Total Successful Daily Deposit of ≥ MYR 7,552</td>
                                                <td>1 point</td>
                                            </tr>
                                            <tr>
                                                <td>Every accumulated Total Daily Stake Amount of ≥ MYR 11,552 </td>
                                                <td>1 point</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ul>
                                        <li>Winners will be based on the highest total points during the promotion period.</li>
                                        <li>Total current points can be checked in the Leaderboard, and will be updated every Monday 18:00 GMT+8 (Weekly)</li>
                                        <li>Valid stake will be based on rebate definition.</li>
                                    </ul>
                                </li>
                                <li>Members must click the <strong>"OPT-IN"</strong> button (after login) and submit before the promotion period ends.</li>
                                <li>All draw bets, void bets, and bets at decimal odds below 1.50 (Malay odds 0.50; HK odds 0.50; Indo Odds -2.00) and bets placed on excluded games will not be included in the calculation for any turnover and rollover requirement.</li>
                                <li>Bonus must meet the 5 times rollover requirement in <strong>"SABA &amp;/or MSPORTS"</strong> before any withdrawal can be made.</li>
                                <li>Rollover Contribution will be 100% on MSPORTS and 80% on SABA Sports (except the games that are in Exclusion List)</li>
                                <li>Bonus will be credited to the members’ wallet automatically within 5 days after the promotion ends. </li>
                                <li>Lucky winners will be notified within 5 days after the promotion ends. If winner cannot be contacted, the prize will be considered void and there will be no further draw after the period ends. Unused bonus amount will expire 5 days after the bonus has been credited.</li>
                                <li>General Terms &amp; Conditions of Promotions apply.</li>
                            </ol>
                            <ol>
                                <strong>Prize Structure as below:</strong>
                                <table className="prizetable">
                                    <thead>
                                        <tr>
                                            <td>Rank</td><td colSpan={2}><strong>Prize ( MYR )</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Grand Prize</td><td colSpan={2}><strong>2 VIP Tickets </strong><br />(FC Barcelona Match Live in Camp Nou)</td>
                                        </tr>
                                        <tr>
                                            <td>1</td><td>48,000</td><td rowSpan={5}>+ Limited Edition Autographed T-shirt by La Liga Ambassadors</td>
                                        </tr>
                                        <tr>
                                            <td>2</td><td>30,000</td>
                                        </tr>
                                        <tr>
                                            <td>3</td><td>24,000</td>
                                        </tr>
                                        <tr>
                                            <td>4</td><td>18,000</td>
                                        </tr>
                                        <tr>
                                            <td>5</td><td>14,000</td>
                                        </tr>
                                        <tr>
                                            <td>6</td><td>10,000</td><td rowSpan={5}>+ Limited Edition Autographed Soccer Ball by La Liga Ambassadors</td>
                                        </tr>
                                        <tr>
                                            <td>7</td><td>6,000</td>
                                        </tr>
                                        <tr>
                                            <td>8</td><td>4,000</td>
                                        </tr>
                                        <tr>
                                            <td>9</td><td>3,500</td>
                                        </tr>
                                        <tr>
                                            <td>10</td><td>3,000</td>
                                        </tr>
                                        <tr>
                                            <td>11 - 20</td><td>2,000</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>21 - 30</td><td>1,600</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>31 - 50</td><td>1,200</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>51 - 100</td><td>800</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>101 - 200</td><td>400</td><td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p><strong>* 1 Grand Prize – 2 VIP tickets to watch FC Barcelona match live in Camp Nou </strong></p>
                                <p><strong>Notes:</strong></p>
                                <ul>
                                    <li>2 Business Class Round Trips Tickets from winner’s origin country (1 Winner)</li>
                                    <li>Airport transfers</li>
                                    <li>5D4N 5 Stars hotel (near the venue Camp Nou Stadium)</li>
                                    <li>Winner will be responsible for their own visa going to Barcelona - Spain if required. Company will not shoulder the visa processing and expenses.</li>
                                    <li>Company will be the one who choose the airline &amp; the hotel.</li>
                                    <li>Grand Prize will be forfeited if winner is unable to contact within 5 days after the promotion period end.</li>
                                    <li>Grand Prize can be change to MYR 40,000 Free Cash with no Rollover.</li>
                                </ul>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
