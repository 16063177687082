import React, { Component } from 'react'

import TextTranslation from './translation'

export default class playnow extends Component {
    constructor(props) {
        super(props)
        this.state = { message: 'Play on M88' };
        this.gotom88 = this.gotom88.bind(this)
    }

    gotom88() {
        let m88link = ''         
        switch (this.props.lang) {
            case "zh-CN":
                m88link = "https://www.m3878.com";
                break;
            case "id-ID":
                m88link = "https://www.btvm88.com";
                break;
            case "th-TH":
                m88link = "https://www.88ab88.com";
                break;
            case "vi-VN":
                m88link = "https://www.ms8kk.com";
                break;
            default:
                m88link = "https://www.m88myr.com";
        }

        window.location = m88link
    }

    render() {
        return (
            <button onClick={this.gotom88}><TextTranslation lang={this.props.lang} textcontent="Play Now!" /></button>
        )
    }
}
