import React, { Component } from 'react'

export default class tnc_en extends Component {
    componentDidMount(){      
        var acc = document.getElementById("accordion");

        acc.addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        } 
        });
    }
    render() {
        return (
            <div className="container tnc">
                <h2>Syarat &amp; Ketentuan Promosi </h2>
                <button id="accordion" className="accordion">VIP JOURNEY TO EURO 2020</button>
                <div className="panel">
                    <div className="content">
                        <p>PERJALANAN EURO 2020  ANDA DI MULAI SEKARANG!</p>
                        <h3>Syarat &amp; Ketentuan:</h3>
                        <div className="row">
                            <ol>
                                <li>Promosi ini berlaku khusus untuk seluruh member VIP Gold, Platinum dan Diamond dari China, Vietnam, Indonesia, Thailand dan Malaysia.
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>PERIODE PROMOSI</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong> 12 Mei 2021,</strong> 00:00:00 sampai<strong> 11 Juni 2021,</strong> 23:59:59 (GMT+8)</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>Selama masa periode promosi, member yang bermain di <strong>“Msports dan/atau SABA Sports”</strong> dengan total poin tertinggi akan berkesempatan memenangkan <strong>tiket VIP untuk menonton pertandingan FC Barcelona secara langsung di Camp Nou</strong>.</li>
                                <li>Untuk memenangkan hadiah, member harus mengumpulkan poin berdasarkan kriteria di bawah ini:
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>Cara Mengumpulkan Poin</td>
                                                <td>Poin</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Setiap akumulasi total deposit harian sebesar IDR 26,450 </td>
                                                <td>1 poin</td>
                                            </tr>
                                            <tr>
                                                <td>Setiap akumulasi total taruhan harian sebesar IDR 40,450 </td>
                                                <td>1 poin</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ul>
                                        <li>Pemenang akan ditentukan berdasarkan dari total poin tertinggi selama periode promosi.</li>
                                        <li>Total poin saat ini dapat diperiksa pada Leaderboard, dan akan diperbarui setiap minggunya pada hari Senin 18:00 GMT+8.</li>
                                        <li>Taruhan yang memenuhi syarat akan didasarkan pada ketentuan perhitungan rebate.</li>
                                    </ul>
                                </li>
                                <li>Klaim promosi ini dengan klik pada tombol <strong>"OPT-IN"</strong> (Setelah Login) dan pastikan klaim sebelum masa promosi berakhir. Promosi dapat diklaim sekali saja dan akan berlaku selama masa periode promosi. Kami tidak akan mengkreditkan bonus untuk periode yang sudah terlewatkan.</li>
                                <li>Hanya taruhan yang memenuhi syarat yang akan diperhitungkan dalam bonus dan rollover. Taruhan yang tidak memenuhi syarat termasuk taruhan seri, taruhan yang dibatalkan, taruhan pada dua sisi &amp; taruhan di bawah odds decimal 1.50 (Malay odds 0.50; HK odds 0.50; Indo Odds -2.00).</li>
                                <li>Bonus harus diputar sebanyak 5x Rollover di dompet <strong>"Msports/SABA Sports</strong>" sebelum penarikan dapat dilakukan.</li>
                                <li>Bonus akan dikreditkan ke akun member dalam waktu 5 hari kerja setelah promosi berakhir. Dana bonus akan hangus jika tidak digunakan dalam waktu 5 hari setelah bonus dikreditkan.</li>
                                <li>Pemenang yang beruntung akan diinformasikan dalam waktu 5 hari kerja setelah promosi berakhir. Jika pemenang tidak dapat dihubungi maka hadiah akan dianggap hangus dan tidak akan ada pengundian ulang setelah periode berakhir.</li>
                                <li>Ketentuan rollover harus dipenuhi dalam waktu 30 hari setelah dana bonus dikreditkan untuk menghindari pembatalan bonus dan kemenangan.</li>
                                <li>Syarat &amp; Ketentuan Umum Promosi berlaku.</li>
                            </ol>                        
                            <ol>
                                <strong>Struktur Hadiah adalah sebagai berikut:</strong>
                                <table className="prizetable">
                                    <thead>
                                        <tr>
                                            <td><strong>PERINGKAT</strong></td><td><strong>HADIAH (IDR)</strong></td><td><strong>Hadiah Tambahan</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Grand Prize</td><td colSpan={2}>Tiket Nonton VIP - FC Barcelona [ Camp Nou ]</td>
                                        </tr>
                                        <tr>
                                            <td>1</td><td>168,000</td><td rowSpan={5}>+ 1 Edisi Terbatas T-shirt La Liga yang ditanda-tangani oleh Duta Besar La Liga </td>
                                        </tr>
                                        <tr>
                                            <td>2</td><td>108,000</td>
                                        </tr>
                                        <tr>
                                            <td>3</td><td>84,000</td>
                                        </tr>
                                        <tr>
                                            <td>4</td><td>63,000</td>
                                        </tr>
                                        <tr>
                                            <td>5</td><td>50,000</td>
                                        </tr>
                                        <tr>
                                            <td>6</td><td>35,000</td><td rowSpan={5}>+ 1 Edisi Terbatas Bola yang ditanda-tangani oleh Duta Besar La Liga</td>
                                        </tr>
                                        <tr>
                                            <td>7</td><td>20,000</td>
                                        </tr>
                                        <tr>
                                            <td>8</td><td>14,000</td>
                                        </tr>
                                        <tr>
                                            <td>9</td><td>12,000</td>
                                        </tr>
                                        <tr>
                                            <td>10</td><td>11,000</td>
                                        </tr>
                                        <tr>
                                            <td>11-20</td><td>7,000</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>21-30</td><td>5,600</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>31-50</td><td>4,200</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>51-100</td><td>2,800</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>101-200</td><td>1,400</td><td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p><strong>1 Hadiah Grand Prize – 2 Tiket VIP untuk menonton pertandingan FC Barcelona secara langsung di Camp Nou</strong></p>
                                <p>Rincian:</p>
                                <ul>
                                    <li>2 Tiket PP kelas Bisnis dari negara asal pemenang (1 Pemenang)</li>
                                    <li>Airport transfer</li>
                                    <li>Hotel Bintang 5 selama 5 hari 4 malam (dekat Stadium Camp Nou)</li>
                                    <li>Pemenang akan bertanggung jawab atas pengurusan visa ke Barcelona – Spanyol jika diperlukan. Perusahaan tidak akan mengurus dan tidak menanggung biaya pembuatan visa.</li>
                                    <li>Perusahaan akan menentukan hotel dan maskapai yang akan digunakan.</li>
                                    <li>Biaya lainnya selain dari yang disebutkan akan menjadi tanggung jawab pemenang.</li>
                                    <li>Grand Prize akan hangus apabila pemenang tidak dapat dihubungi dalam waktu 5 hari kerja setelah periode promosi berakhir.</li>
                                    <li>Grand Prize dapat ditukar dengan Uang Tunai sebesar IDR 140,000 tanpa rollover.</li>
                                </ul>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
