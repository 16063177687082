import React, { Component } from 'react'

export default class leaderboardtable extends Component {
    constructor(props) {
        super(props);
        this.state = { 
        };
    }
    render() {

        const isOddEven = (number) => {
            if(number % 2 === 0) 
                { return "even" }
            else { return "odd" }
        }

        const isTop3 = (number) => {
            if(number < 3)
                { return "top3"}
            else { return "" }
        }

        const tablerowclassname = (number) => {
            if (this.props.startat === 0){
                return "tablerow " + isOddEven(number) + " " + isTop3(number)
            }
            else {
                return "tablerow " + isOddEven(number)
            }
        }

        const mask = (text) => {
            if(typeof text === 'number'){
                text = text.toString()
            }
            let maskedText = ''
            let i = 0
            for (i = 0; i < text.length; i++) {
                if(i === 2 || i === 3 || i === 4 || i === 7 || i === 8 || i === 9 || i === 13 || i === 14) {
                    maskedText = maskedText + "*"
                }
                else {
                    maskedText = maskedText + text[i]
                }
            }
            return maskedText
        }

        const addComa = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        
        const tablerows = this.props.list.map((list,index) => {
            return(    
                <div className={tablerowclassname(index)} key={index}>
                    <div className="tablecell rank">{this.props.startat+index+1}</div>
                    <div className="tablecell username">
                        <div className={"flag "+list.currency}></div>
                        <span>{mask(list.username)}</span>
                    </div>
                    <div className="tablecell ticket">{addComa(list.prize)}</div>
                </div>
            )      
        })

        return (
            <>{tablerows}</>
        )
    }
}
