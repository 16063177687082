import React, { Component } from 'react'
import { readRemoteFile } from 'react-papaparse';

import '../styles/leaderboard.css'

import LeaderboardTable from './leaderboardtable'
import TextTranslation from './translation'

export default class leaderboard extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = { 
            list: [] 
        };
    }

    componentDidMount(){
        let csvfile = 'https://'+window.location.hostname+'/data/vip-journeytoeuro2020.csv'

        /* if (this.props.lang === 'zh-CN'){
            csvfile = 'https://'+window.location.hostname+'/data/rap-points_en.csv'
        }
        else if (this.props.lang === 'id-ID'){
            csvfile = 'https://'+window.location.hostname+'/data/rap-points_en.csv'
        }
        else if (this.props.lang === 'th-TH'){
            csvfile = 'https://'+window.location.hostname+'/data/rap-points_en.csv'
        }
        else {
            csvfile = 'https://'+window.location.hostname+'/data/rap-points_en.csv'
        } */

        readRemoteFile(csvfile, {
            header: true,
            dynamicTyping: true,
            complete: (results) => {
                this.setState({
                    list: results.data
                });
            }
        });
    };

    render() {    

        const compareValues = (key, order = 'asc')=> {
            return function innerSort(a, b) {
                if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                    // property doesn't exist on either object
                    return 0;
                }

                const varA = (typeof a[key] === 'string')
                    ? a[key].toUpperCase() : a[key];
                const varB = (typeof b[key] === 'string')
                    ? b[key].toUpperCase() : b[key];

                let comparison = 0;
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }

        const sortedList = (start, end) => this.state.list.sort(compareValues('ticket', 'desc')).slice(start,end)

        return (
            <section>
                <div className="container">
                    <div className="leaderboardheader">
                        <div className="col left">
                            <span><TextTranslation lang={this.props.lang} textcontent="Leaderboard" /></span>
                            <h1><TextTranslation lang={this.props.lang} textcontent="VIP JOURNEY TO EURO 2020" /></h1>
                        </div>
                        <div className="col right">
                            <p><TextTranslation lang={this.props.lang} textcontent="Promo Period: May 12 - June 11, 2021" /></p>
                        </div>
                    </div>
                    {/* <div className="blankspace"></div> */}
                    <div className="leaderboard top">
                        <div className="tableheader">
                            <div className="tablecell rank">
                                <span className="mddisplay"><TextTranslation lang={this.props.lang} textcontent="Top 1 - 10" /></span>
                                <span className="smdisplay"><TextTranslation lang={this.props.lang} textcontent="Top 20" /></span>
                            </div>
                            <div className="tablecell username"><TextTranslation lang={this.props.lang} textcontent="Username" /></div>
                            <div className="tablecell ticket"><TextTranslation lang={this.props.lang} textcontent="Points" /></div>
                        </div>
                        <LeaderboardTable list={sortedList(0,10)} startat={0} lang={this.props.lang} />
                    </div>
                    <div className="leaderboard below">
                        <div className="col left">
                            <div className="tableheader">
                                <div className="tablecell rank"><TextTranslation lang={this.props.lang} textcontent="Top 11 - 20" /></div>
                                <div className="tablecell username"><TextTranslation lang={this.props.lang} textcontent="Username" /></div>
                                <div className="tablecell ticket"><TextTranslation lang={this.props.lang} textcontent="Points" /></div>
                            </div>
                            <LeaderboardTable list={sortedList(10,20)} startat={10} lang={this.props.lang} />
                        </div>
                        <div className="col right">
                            <div className="tableheader">
                                <div className="tablecell rank"><TextTranslation lang={this.props.lang} textcontent="Top 21 - 30" /></div>
                                <div className="tablecell username"><TextTranslation lang={this.props.lang} textcontent="Username" /></div>
                                <div className="tablecell ticket"><TextTranslation lang={this.props.lang} textcontent="Points" /></div>
                            </div>
                            <LeaderboardTable list={sortedList(20,30)} startat={20} lang={this.props.lang} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
