import React, { Component } from 'react'
import { BsVolumeMuteFill, BsVolumeUpFill } from "react-icons/bs";
import ReactTooltip from 'react-tooltip'

import Countdown from './countdown'
import TextContent from './promovideo.content'
import classes from '../styles/promovideo.module.css';

export default function BackgroundVideo(props) {
    React.useEffect(() => {
      // code to run after render goes here
        setTimeout(function () {
            document.getElementById("addsoundtip").classList.add("show");
        }, 2000);
    });
        
    let videoSource = ""

    if (props.lang === 'zh-CN'){
        videoSource = 'https://'+window.location.hostname+'/videos/JourneyToEuro-PromoVideo_CN.mp4'
    }
    else if (props.lang === 'id-ID'){
        videoSource = 'https://'+window.location.hostname+'/videos/JourneyToEuro-PromoVideo_ID.mp4'
    }
    else if (props.lang === 'th-TH'){
        videoSource = 'https://'+window.location.hostname+'/videos/JourneyToEuro-PromoVideo_TH.mp4'
    }
    else if (props.lang === 'vi-VN'){
        videoSource = 'https://'+window.location.hostname+'/videos/JourneyToEuro-PromoVideo_VN.mp4'
    }
    else {
        videoSource = 'https://'+window.location.hostname+'/videos/JourneyToEuro-PromoVideo_EN.mp4'
    }

    return (
        <section className={classes.VideoSection} >
            <div className={classes.VideoContainer} >
                <video id="myVideo" autoPlay="autoplay" loop="loop" className={classes.Video} muted playsinline
                    onClick={()=>{
                    document.getElementById("myVideo").muted = false;
                    document.getElementById("Mute").style.display = "block";
                    document.getElementById("UnMute").style.display = "none";
                    }}>
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className={classes.VideoControl} >
                <div id="UnMute"  className={classes.VideoUnmute} data-tip data-for='addsoundtip'>
                    <BsVolumeMuteFill onClick={()=>{
                        document.getElementById("myVideo").muted = false;
                        document.getElementById("Mute").style.display = "block";
                        document.getElementById("UnMute").style.display = "none";
                        }} color="#ffcd00"/>
                </div>
                <ReactTooltip id='addsoundtip' type='light'>
                    <span><TextContent lang={props.lang} textcontent="Tap Here to Add Sound" /></span>
                </ReactTooltip>
                <div id="Mute"  className={classes.VideoMute} data-tip data-for='mutetooltip'>
                    <BsVolumeUpFill onClick={()=>{
                        document.getElementById("myVideo").muted = true;
                        document.getElementById("Mute").style.display = "none";
                        document.getElementById("UnMute").style.display = "block";
                        }} color="#ffcd00"/>
                </div>
                <ReactTooltip id='mutetooltip' type='light'>
                    <span><TextContent lang={props.lang} textcontent="Tap to Mute Video" /></span>
                </ReactTooltip>
            </div>
            {/* <Countdown lang={props.lang}/> */}
        </section>
    )
}

