import React, { Component } from 'react'

import SupplierOpus from '../assets/gaming_Footer_Opus_Supplier.svg'
import SupplierAG from '../assets/gaming_Footer_AG_Supplier.svg'
import SupplierCQ9 from '../assets/gaming_Footer_CQ9_Supplier.svg'
import SupplierHabanero from '../assets/gaming_Footer_Habanero_Supplier.svg'
import SupplierIsoftbet from '../assets/gaming_Footer_ISoftBet_Supplier.svg'
import SupplierMGS from '../assets/gaming_Footer_MGS_Supplier.svg'
import SupplierSkywind from '../assets/gaming_Footer_SkyWind_Supplier.svg'
import SupplierPragmatic from '../assets/gaming_Footer_Pragmatic_Supplier.svg'
import SupplierSaba from '../assets/gaming_Footer_SabaSports_Supplier.svg'
import SupplierPNG from '../assets/gaming_Footer_PlayNGO_Supplier.svg'
import SupplierLottoland from '../assets/gaming_Footer_Lottoland_Supplier.svg'
import SupplierIDNPlay from '../assets/gaming_Footer_IDNPlay_Supplier.svg'
import SupplierPlaytech from '../assets/gaming_Footer_Playtech_Supplier.svg'
import SupplierNetent from '../assets/gaming_Footer_Netent_Supplier.svg'

import logoLaligaVertical_EN from '../assets/laliga-logo-vertical_EN.svg'
import logoLaligaHorizontal_EN from '../assets/laliga-logo-horizontal_EN.svg'

import SponsorBournemouth from '../assets/teams_Footer_Bournemouth_Sponsorship.svg'
import SponsorCrystalPalace from '../assets/teams_Footer_CrystalPalace_Sponsorship.svg'
import SponsorManCity from '../assets/teams_Footer_Manchester_Sponsorship.svg'
import SponsorTottenham from '../assets/teams_Footer_Tottenham_Sponsorship.svg'

import LicenseGA from '../assets/Footer_GA_License.svg'
import LicenseItech from '../assets/Footer_iTech_License.svg'

export default class footer extends Component {
    render() {
        return (
            <footer>
                <section>
                    <div className="container gamesupplier">
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierOpus} alt="Opus Gaming" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierAG} alt="Asia Gaming" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierCQ9} alt="CQ9 Gaming" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierHabanero} alt="Habanero" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierIsoftbet} alt="iSoftbet" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierMGS} alt="MicroGaming System" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierSkywind} alt="SkyWind" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierPragmatic} alt="Pragmatic" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierSaba} alt="Saba Sports" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierPNG} alt="Plan N Go" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierLottoland} alt="Lottoland" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierIDNPlay} alt="IDN Play" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierPlaytech} alt="Playtech" />
                        </div>
                        <div className="vendor">
                            <img className="vendorlogo" src={SupplierNetent} alt="Netent" />
                        </div>
                    </div>
                    <div className="container sponsorandlicense">
                        <div className="sponsorship">
                            <div className="sponsor laliga">
                                <img className="imagesponsor vertical" src={logoLaligaVertical_EN} alt="La Liga Regional Partner" />
                                <img className="imagesponsor horizontal" src={logoLaligaHorizontal_EN} alt="La Liga Regional Partner" />
                                <div className="sponsordetails"><strong><span>Official Regional Partner</span></strong><br/>2020 - 2021</div>
                            </div>
                            <div className="sponsor">
                                <img className="imagesponsor" src={SponsorBournemouth} alt="AFC Bournemouth" />
                                <div className="sponsordetails">
                                    <strong>AFC Bournemouth</strong><br/>2016 - 2020
                                </div>
                            </div>
                            <div className="sponsor">
                                <img className="imagesponsor" src={SponsorCrystalPalace} alt="Crystal Palace" />
                                <div className="sponsordetails">
                                    <strong>Crystal Palace</strong><br/>2015 - 2017
                                </div>
                            </div>
                            <div className="sponsor">
                                <img className="imagesponsor" src={SponsorManCity} alt="Manchester City" />
                                <div className="sponsordetails">
                                    <strong>Manchester City</strong><br/>2011 - 2012
                                </div>
                            </div>
                            <div className="sponsor">
                                <img className="imagesponsor" src={SponsorTottenham} alt="Tottenham Hotspurs" />
                                <div className="sponsordetails">
                                    <strong>Tottenham Hotspurs</strong><br/>2006 - 2010
                                </div>
                            </div>
                        </div>
                        <div className="licenses">
                            <img className="imageLicense" src={LicenseGA} alt="GA" />
                            <img className="imageLicense" src={LicenseItech} alt="iTech" />
                        </div>
                    </div>
                    <div className="container copyright">
                        <div className="text-left">
                            <div>M88 is committed to supporting <strong>Responsible Gaming</strong></div>
                            <div className="licenses">
                                <img className="imageLicense" src={LicenseGA} alt="GA" />
                                <img className="imageLicense" src={LicenseItech} alt="iTech" />
                            </div>
                        </div>
                        <div className="text-right">
                            <div className="eclipse"><strong>18+</strong></div>|  M88.com 2021
                        </div>
                    </div>
                </section>
            </footer>
        )
    }
}
