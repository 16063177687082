import React, { Component } from 'react'

import TnC_EN from './tnc_en'
import TnC_CN from './tnc_cn'
import TnC_ID from './tnc_id'
import TnC_TH from './tnc_th'
import TnC_VN from './tnc_vn'

export default class tnc extends Component {
    render() {
        const tncContent = (lang) => {
            let content = ''         
            switch (lang) {
                case "zh-CN":
                    content = <TnC_CN />;
                    break;
                case "id-ID":
                    content = <TnC_ID />;
                    break;
                case "th-TH":
                    content = <TnC_TH />;
                    break;
                case "vi-VN":
                    content = <TnC_VN />;
                    break;
                default:
                    content = <TnC_EN />;
            }
            return content
        }

        return (
            <section>
                {tncContent(this.props.lang)}
            </section>
        )
    }
}
