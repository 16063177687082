import React, { Component } from 'react'

import '../styles/header.css'

import TextTranslation from './translation'

import m88logo from '../assets/m88-laliga-logo_EN.svg';
import flagEN from '../assets/flag-en.svg';
import flagCN from '../assets/flag-cn.svg';
import flagID from '../assets/flag-id.svg';
import flagTH from '../assets/flag-th.svg';
import flagVN from '../assets/flag-vn.svg';

export default class header extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            searchresult: ''
        }
        this.gotom88 = this.gotom88.bind(this)
    }

    componentDidMount (){
        // Initialize Variables
        //const closePopup = document.getElementById("popupclose");
        const overlay = document.getElementById("overlay");
        const popup = document.getElementById("popup");
        const myticket = document.getElementById("myticket");
        // Close Popup Event
        //closePopup.onclick = () => {
            //overlay.className = ''; /*This removes all classes, use at your own risk*/
            //popup.className = ''; /*This removes all classes, use at your own risk*/
        //};
        // Close Popup Event
        //overlay.onclick = () => {
            //overlay.className = ''; /*This removes all classes, use at your own risk*/
            //popup.className = ''; /*This removes all classes, use at your own risk*/
        //};
        // Show Overlay and Popup
        myticket.onclick = () => {
            overlay.className = 'show';
            popup.className = 'show';
        }
    }

    gotom88() {
        let m88link = ''         
        switch (this.props.lang) {
            case "zh-CN":
                m88link = "https://www.ctvm88.com";
                break;
            case "id-ID":
                m88link = "https://www.t1688.net";
                break;
            case "th-TH":
                m88link = "https://www.88ab88.com";
                break;
            case "vi-VN":
                m88link = "https://www.ms8kk.com";
                break;
            default:
                m88link = "https://www.m88myr.com";
        }

        window.location = m88link
    }

    render() {
        const currentLang = (toshow) => {
            let lang = ''
            let flag = ''
            switch (this.props.lang) {
                case "zh-CN":
                    lang = "简体中文";
                    flag = flagCN;
                    break;
                case "id-ID":
                    lang = "Bhs Indonesia";
                    flag = flagID;
                    break;
                case "th-TH":
                    lang = "ไทย";
                    flag = flagTH;
                    break;
                case "vi-VN":
                    lang = "Tiếng Việt";
                    flag = flagVN;
                    break;
                default:
                    lang = "English";
                    flag = flagEN;
            }
            if (toshow === 'flag') {
                return flag
            }
            if (toshow === 'lang') {
                return lang
            }
        }

        return (
            <header>
                <section>
                    <div className="container">
                        <div className="col left">
                            <img onClick={this.gotom88} className="imageLogo" src={m88logo} alt="M88" />
                        </div>
                        <div className="col right">
                            <button id="myticket"><TextTranslation lang={this.props.lang} textcontent="My Ranking" /></button>
                            <div className="langselect">
                                <div className="dropbtn">
                                    <img className="flag" src={currentLang('flag')} alt="M88" />
                                    <span>{currentLang('lang')}</span><i className="arrow down"></i>
                                </div>
                                <div className="dropdown-content">
                                    <a href={"/?lang=en-US"}><img className="flag" src={flagEN} alt="M88" /><span>English</span></a>
                                    <a href={"/?lang=zh-CN"}><img className="flag" src={flagCN} alt="M88" /><span>简体中文</span></a>
                                    <a href={"/?lang=id-ID"}><img className="flag" src={flagID} alt="M88" /><span>Bhs Indonesia</span></a>
                                    <a href={"/?lang=th-TH"}><img className="flag" src={flagTH} alt="M88" /><span>ไทย</span></a>
                                    <a href={"/?lang=vi-VN"}><img className="flag" src={flagVN} alt="M88" /><span>Tiếng Việt</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </header>
        )
    }
}
