import React, { Component } from 'react'

export default class tnc_en extends Component {
    componentDidMount(){      
        var acc = document.getElementById("accordion");

        acc.addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        } 
        });
    }
    render() {
        return (
            <div className="container tnc">
                <h2>ข้อกำหนดและเงื่อนไขโปรโมชั่น </h2>
                <button id="accordion" className="accordion">VIP เส้นทางสู่ยูโร 2020 </button>
                <div className="panel">
                    <div className="content">
                        <p>เริ่มต้นการเดินทาง เข้าชม ถ่ายทอดสด FC บาร์เซโลน่า ที่สนามคัมป์ นู กับเราที่นี่</p>
                        <h3>ข้อกำหนดและเงื่อนไข:</h3>
                        <div className="row">
                            <ol>
                                <li>โปรโมชั่นนี้สำหรับลูกค้าวีไอพีระดับโกลด์ แพลตินั่ม และไดมอนด์ทั้งหมดที่อยู่ในประเทศไทย จีน เวียดนาม อินโดนีเซีย และมาเลเซีย</li>
                                <li>สมาชิกจะต้องคลิกปุ่ม "รับโบนัสเดี๋ยวนี้" (หลังจากเข้าสู่ระบบ) เพื่อเข้าร่วมโปรโมชั่น
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>ระยะเวลาโปรโมชั่น (GMT+8)</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong> 12 พฤษภาคม 2564 </strong>เวลา 00:00:00 จนถึง วันที่ <strong> 11 มิถุนายน 2564</strong>เวลา 23:59:59</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>ในช่วงระยะเวลาโปรโมชั่น สมาชิกที่เดิมพันใน "SABA สปอร์ต(กีฬา) หรือ เอ็มสปอร์ต" และมีแต้มสะสมสูงสุดจะได้รับรางวัลตั๋วVIPเข้าชมการแข่งขันสดแมตช์ FC Barcelona ที่สนามกัมนอว์ ประเทศสเปน</li>
                                <li>วิธีการสะสมแต้ม สมาชิกจะต้องทำการเดิมพันตามรายละเอียดที่แสดงไว้ดังตารางด้านล่าง
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>วิธีการสะสมแต้ม – วีไอพีระดับโกลด์ แพลตินั่ม และไดมอนด์</td>
                                                <td>จำนวน</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>ทุกๆ ยอดฝากที่สำเร็จรวมรายวัน ≥ 60,416 บาท</td>
                                                <td>1 แต้ม</td>
                                            </tr>
                                            <tr>
                                                <td>ทุกๆ ยอดวางเดิมพันรวมรายวัน ≥ 92,416 บาท</td>
                                                <td>1 แต้ม</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ul>
                                        <li>ผู้ชนะจะขึ้นอยู่กับลำดับยอดสะสมแต้มสูงสุดภายในระยะเวลาโปรโมชั่น</li>
                                        <li>สามารถตรวจสอบแต้มสะสมได้ที่ ลีดเดอร์บอร์ด, จะมีการอัพเดททุกวันจันทร์ เวลา 18:00 GMT+8 (ทุกสัปดาห์) </li>
                                        <li>ยอดเดิมพันที่นำมาคำนวณจะขึ้นอยู่กับคำจำกัดความของการคืนเงิน </li>
                                    </ul>
                                </li>
                                <li>โบนัสที่ได้รับสมาชิกจะต้องทำยอดเดิมพันหมุนเวียน 5 เท่าใน <strong>"SABA สปอร์ต(กีฬา) หรือ เอ็มสปอร์ต"</strong> ก่อนทำรายการถอน</li>
                                <li>เฉพาะการเดิมพันโดยใช้เงินจริงเท่านั้น รายการเดิมพันที่มีผลเสมอ โมฆะ ยกเลิก เดิมพันราคาน้ำเดซิมอล 1.50 ราคาน้ำมาเลย์ 0.50 ราคาน้ำฮ่องกง 0.50 และราคาน้ำอินโด -2.00 ราคาน้ำเหล่านี้ ต่ำกว่าหรือ เทียบเท่า จะไม่สามารถนำมาคำนวณในยอดเดิมพันรวม และยอดเดิมพันหมุนเวียนได้</li>
                                <li>โบนัสจะทำการปรับเข้ากระเป๋าเงินของสมาชิกอัตโนมัติ ภายใน 5 วันทำการ หลังจากโปรโมชั่นสิ้นสุดลง หากไม่มีการใช้งาน โบนัสที่ได้รับจะหมดอายุภายใน 5 วันหลังจากวันที่ได้รับโบนัส</li>
                                <li>สมาชิกจะต้องทำยอดเดิมพันหมุนเวียนให้สำเร็จภายใน 30 วันหลังจากได้รับโบนัส หากเกินระยะเวลาที่กำหนด ยอดโบนัสและยอดชนะที่ได้รับจะถือเป็นโมฆะ</li>
                                <li>สิทธิ์ของผู้รับรางวัลจะเป็นโมฆะ หากไม่สามารถติดต่อได้ภายใน 5 วันหลังจากโปรโมชั่นสิ้นสุดลง</li>
                                <li>ข้อตกลงทั่วไปและเงื่อนไขการใช้งานโปรโมชั่น</li>
                            </ol>
                            <ol>
                                <strong>โครงสร้างรางวัลตามตารางด้านล่างนี้ </strong>
                                <table className="prizetable">
                                    <thead>
                                        <tr>
                                            <td><strong>ลำดับ</strong></td><td colSpan={2}><strong>รางวัล (บาท)</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>รางวัลใหญ่</td><td colSpan={2}>ตั๋ว VIP 2 ที่นั่ง<br />(ชมการแข่งขัน FC Barcelona ที่สนามกัมนอว์)</td>
                                        </tr>
                                        <tr>
                                            <td>1</td><td>384,000</td><td rowSpan={5}>เสื้อจาก LaLiga พร้อมลายเซ็น<br />(Limited Edition)</td>
                                        </tr>
                                        <tr>
                                            <td>2</td><td>247,000</td>
                                        </tr>
                                        <tr>
                                            <td>3</td><td>190,000</td>
                                        </tr>
                                        <tr>
                                            <td>4</td><td>145,000</td>
                                        </tr>
                                        <tr>
                                            <td>5</td><td>110,000</td>
                                        </tr>
                                        <tr>
                                            <td>6</td><td>80,000</td><td rowSpan={5}>ลูกฟุตบอล LaLiga พร้อมลายเซ็น<br />(Limited Edition)</td>
                                        </tr>
                                        <tr>
                                            <td>7</td><td>48,000</td>
                                        </tr>
                                        <tr>
                                            <td>8</td><td>32,000</td>
                                        </tr>
                                        <tr>
                                            <td>9</td><td>28,000</td>
                                        </tr>
                                        <tr>
                                            <td>10</td><td>25,000</td>
                                        </tr>
                                        <tr>
                                            <td>11-20</td><td>16,000</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>21-30</td><td>12,800</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>31-50</td><td>9,600</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>51-100</td><td>6,400</td><td>-</td>
                                        </tr>
                                        <tr>
                                            <td>101-200</td><td>3,200</td><td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p><strong>รางวัลใหญ่ – ตั๋วVIPเข้าชมการแข่งขันสดแมตช์ FC Barcelona ที่สนามกัมนอว์ ประเทศสเปน 2 ที่นั่ง</strong><br />หมายเหตุ:</p>
                                <ul>
                                    <li>ตั๋วเครื่องบินชั้นธุรกิจไปกลับจากประเทศต้นทางรวม 2 ที่นั่ง</li>
                                    <li>บริการรับส่งจากสนามบินถึงที่พัก</li>
                                    <li>ที่พักสุดหรูในโรงแรมระดับ 5 ดาว 5 วัน 4 คืน (ใกล้สนามกัมนอว์)</li>
                                    <li>ผู้ชนะรางวัลจะเป็นผู้รับผิดชอบค่าใช้จ่ายในการดำเนินการขอวีซ่าเข้าเมืองบาร์เซโลนาประเทศสเปนแต่เพียงผู้เดียว</li>
                                    <li>โดยทางบริษัทจะเป็นผู้รับผิดชอบและดำเนินการจัดเตรียมตั๋วเครื่องบินชั้นธุรกิจ และโรงแรมให้กับผู้ชนะรางวัล </li>
                                    <li>สิทธิ์ของผู้ชนะรางวัลจะเป็นโมฆะ หากไม่สามารถติดต่อได้ภายใน 5 วันหลังจากโปรโมชั่นสิ้นสุดลง</li>
                                    <li>รางวัลใหญ่สามารถแลกเป็นเงินสดมูลค่า 320,000 บาท โดยไม่ต้องทำยอดหมุนเวียน</li>
                                </ul>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
